import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from "contentful";

export interface TypeTextBlockFields {
  title: EntryFieldTypes.Symbol;
  blockContent: EntryFieldTypes.RichText;
}

export type TypeTextBlockSkeleton = EntrySkeletonType<
  TypeTextBlockFields,
  "textBlock"
>;
export type TypeTextBlock<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode,
> = Entry<TypeTextBlockSkeleton, Modifiers, Locales>;

export function isTypeTextBlock<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode,
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>,
): entry is TypeTextBlock<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "textBlock";
}
