import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from "contentful";

import type { TypeHeroSectionSkeleton } from "./TypeHeroSection";
import type { TypeMediaWithContentSkeleton } from "./TypeMediaWithContent";
import type { TypeMediaWithLinkSkeleton } from "./TypeMediaWithLink";

export interface TypeHeroSlotFields {
  title: EntryFieldTypes.Symbol;
  heroSlides: EntryFieldTypes.Array<
    EntryFieldTypes.EntryLink<
      | TypeHeroSectionSkeleton
      | TypeMediaWithContentSkeleton
      | TypeMediaWithLinkSkeleton
    >
  >;
  theme?: EntryFieldTypes.Symbol<"dark" | "light">;
}

export type TypeHeroSlotSkeleton = EntrySkeletonType<
  TypeHeroSlotFields,
  "heroSlot"
>;
export type TypeHeroSlot<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode,
> = Entry<TypeHeroSlotSkeleton, Modifiers, Locales>;

export function isTypeHeroSlot<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode,
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>,
): entry is TypeHeroSlot<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "heroSlot";
}
