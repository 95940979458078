import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from "contentful";

import type { TypeLinkSkeleton } from "./TypeLink";

export interface TypeMediaWithContentFields {
  name: EntryFieldTypes.Symbol;
  content?: EntryFieldTypes.RichText;
  ctaPrimary?: EntryFieldTypes.EntryLink<TypeLinkSkeleton>;
  ctaSecondary?: EntryFieldTypes.EntryLink<TypeLinkSkeleton>;
  contentPosition?: EntryFieldTypes.Symbol<"center" | "left" | "right">;
  mediaFile: EntryFieldTypes.AssetLink;
  mobileMedia: EntryFieldTypes.AssetLink;
  navigationTextColor?: EntryFieldTypes.Symbol<"dark" | "light">;
}

export type TypeMediaWithContentSkeleton = EntrySkeletonType<
  TypeMediaWithContentFields,
  "mediaWithContent"
>;
export type TypeMediaWithContent<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode,
> = Entry<TypeMediaWithContentSkeleton, Modifiers, Locales>;

export function isTypeMediaWithContent<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode,
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>,
): entry is TypeMediaWithContent<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "mediaWithContent";
}
