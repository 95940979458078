import { Link } from "@remix-run/react";

import { useContentfulInspectorMode } from "@contentful/live-preview/react";

import type { TypeBannerWithLinks } from "~/contentful/compiled";
import { useURL } from "~/contexts";
import { cn } from "~/lib/ui";

import { ContentfulImage } from "./contentful-image";

export const ContentBanner = ({
  content,
  className = "",
}: {
  content: TypeBannerWithLinks<"WITHOUT_UNRESOLVABLE_LINKS", string>;
  className?: string;
}) => {
  const url = useURL();
  const inspectorProps = useContentfulInspectorMode();

  return content ? (
    <>
      <div
        className={cn("relative mx-auto mb-4 h-128 w-full", className)}
        {...inspectorProps({
          entryId: content.sys.id,
          fieldId: "name",
        })}
      >
        {content.fields.banner?.fields.file?.contentType.includes("image/") ? (
          <ContentfulImage
            key={content.fields.name}
            className="h-full w-full object-cover"
            alt={content.fields.banner?.fields.title ?? ""}
            imageUrl={content.fields.banner?.fields.file?.url}
            maxWidth="full"
          />
        ) : (
          <video
            key={content.fields.name}
            autoPlay
            src={content.fields.banner?.fields.file?.url}
            loop
            muted
            className="h-full w-full object-cover"
          />
        )}
        <div className="absolute bottom-4 w-full text-center">
          <div className="inline-block w-60 rounded bg-white p-4 leading-10 shadow-lg">
            <div className="wb-4 block px-8 text-lg font-semibold">
              {content.fields.name}
            </div>
            {content.fields.links &&
              content.fields.links.map(link => (
                <Link
                  className="my-2 block text-lg text-gray-500 underline transition-colors duration-500 hover:text-black"
                  key={link?.sys.id}
                  to={url(link?.fields.link || "")}
                  prefetch="viewport"
                >
                  {link?.fields.title?.trim() || "View More"}
                </Link>
              ))}
          </div>
        </div>
      </div>
    </>
  ) : (
    <>No Content</>
  );
};
