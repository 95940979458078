import { useContentfulInspectorMode } from "@contentful/live-preview/react";

import { H1 } from "~/components/ui/text";

import type { TypeTextBlock } from "../compiled";
import { RichTextRender } from "./rich-text";

export const TextContent = ({
  content,
}: {
  content: TypeTextBlock<"WITHOUT_UNRESOLVABLE_LINKS", string>;
}) => {
  const inspectorProps = useContentfulInspectorMode();

  return (
    <div
      className="mx-auto max-w-lg text-center text-base"
      {...inspectorProps({
        entryId: content.sys.id,
        fieldId: "name",
      })}
    >
      <H1 className="border-b-0 pb-2 font-semibold transition-colors first:mt-0">
        {content.fields.title}
      </H1>
      <RichTextRender content={content.fields.blockContent} />
    </div>
  );
};
