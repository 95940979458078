import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from "contentful";

export interface TypeMediaWithLinkFields {
  title: EntryFieldTypes.Symbol;
  mediaFile: EntryFieldTypes.Array<EntryFieldTypes.AssetLink>;
  link: EntryFieldTypes.Symbol;
  ctaTitle?: EntryFieldTypes.Symbol;
  navigationTextColor?: EntryFieldTypes.Symbol<"dark" | "light">;
  position?: EntryFieldTypes.Symbol<"center" | "left" | "right">;
}

export type TypeMediaWithLinkSkeleton = EntrySkeletonType<
  TypeMediaWithLinkFields,
  "mediaWithLink"
>;
export type TypeMediaWithLink<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode,
> = Entry<TypeMediaWithLinkSkeleton, Modifiers, Locales>;

export function isTypeMediaWithLink<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode,
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>,
): entry is TypeMediaWithLink<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "mediaWithLink";
}
