import type { Entry } from "contentful";
import { Fragment } from "react";
import { Autoplay, Pagination } from "swiper/modules";

import { Link } from "@remix-run/react";

import { useContentfulInspectorMode } from "@contentful/live-preview/react";

import { Button } from "~/components/ui/button";
import { Carousel } from "~/components/ui/carousel";
import {
  type TypeHeroSectionSkeleton,
  type TypeHeroSlot,
  type TypeMediaWithContent,
  type TypeMediaWithContentSkeleton,
  type TypeMediaWithLink,
  type TypeMediaWithLinkSkeleton,
  isTypeMediaWithContent,
  isTypeMediaWithLink,
} from "~/contentful/compiled";
import { useURL } from "~/contexts";
import { cn } from "~/lib/ui";

import { ContentfulImage } from "./contentful-image";
import { RichTextRender } from "./rich-text";

const POSITIONS = {
  left: "items-center md:items-start",
  center: "items-center",
  right: "items-center md:items-end",
};
const TEXT_ALIGNMENTS = {
  left: "text-left",
  center: "text-center",
  right: "text-right",
};

export const HomePageHero = ({
  content,
  className,
}: {
  content: TypeHeroSlot<"WITHOUT_UNRESOLVABLE_LINKS", string>;
  className?: string;
}) => {
  const getSlide = (
    slide:
      | Entry<
          | TypeMediaWithLinkSkeleton
          | TypeMediaWithContentSkeleton
          | TypeHeroSectionSkeleton,
          "WITHOUT_UNRESOLVABLE_LINKS",
          string
        >
      | undefined,
  ) => {
    if (!slide) return null;
    if (isTypeMediaWithContent(slide))
      return <MediaWithContent key={slide?.sys.id} content={slide} />;
    if (isTypeMediaWithLink(slide))
      return <MediaWithLinkContent key={slide?.sys.id} content={slide} />;
    return null;
  };
  const inspectorProps = useContentfulInspectorMode();
  return (
    <div
      className={cn(
        "relative mx-auto mb-4 flex h-96 w-full flex-wrap justify-between",
        className,
        content.fields.theme === "light" ? "text-white" : "text-black",
      )}
      {...inspectorProps({
        entryId: content.sys.id,
        fieldId: "name",
      })}
    >
      <Carousel
        loop={true}
        pagination={{
          bulletClass: "swiper-pagination-bullet ",
          clickable: true,
        }}
        spaceBetween={0}
        fadeEffect={{
          crossFade: true,
        }}
        autoplay={{
          delay: 8000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination]}
      >
        {content && content?.fields.heroSlides.map(slide => getSlide(slide))}
      </Carousel>
    </div>
  );
};

export const MediaWithLinkContent = ({
  content,
}: {
  content: TypeMediaWithLink<"WITHOUT_UNRESOLVABLE_LINKS", string>;
}) => {
  const url = useURL();
  const file = content.fields.mediaFile[0]?.fields.file;
  const title = content.fields.title;
  const link = content.fields.link;
  const ctaTitle = content.fields.ctaTitle;
  const positionOn = "text-" + content.fields.position;
  const inspectorProps = useContentfulInspectorMode();
  return (
    <Fragment
      key={content.sys.id}
      {...inspectorProps({
        entryId: content.sys.id,
        fieldId: "name",
      })}
    >
      {file?.contentType.includes("image") ? (
        <ContentfulImage
          key={file?.fileName}
          className="h-full w-full object-cover"
          alt={file?.fileName}
          imageUrl={file?.url}
          maxWidth="full"
        />
      ) : (
        <video
          muted
          key={file?.fileName}
          autoPlay
          src={file?.url}
          loop
          playsInline
          className="h-full w-full object-cover"
        />
      )}
      <div
        className={cn(
          "absolute bottom-20 box-border w-full  px-8 font-semibold duration-500",
          positionOn,
        )}
      >
        {title}
      </div>
      <div
        className={cn(
          "absolute bottom-10 box-border w-full px-8 font-semibold",
          positionOn,
        )}
      >
        {link && (
          <Button as={Link} prefetch={"intent"} to={url(link)}>
            {ctaTitle}
          </Button>
        )}
      </div>
    </Fragment>
  );
};
export const MediaWithContent = ({
  content,
}: {
  content: TypeMediaWithContent<"WITHOUT_UNRESOLVABLE_LINKS", string>;
}) => {
  const url = useURL();
  const title = content.fields.name;
  const positionOn = POSITIONS[content.fields.contentPosition || "left"];
  const alignText = TEXT_ALIGNMENTS[content.fields.contentPosition || "left"];
  const file = content.fields.mediaFile?.fields.file;
  const mobileFile = content.fields.mobileMedia?.fields.file;
  const ctaPrimary = content.fields.ctaPrimary?.fields;
  const ctaSecondary = content.fields.ctaSecondary?.fields;
  const textContent = content.fields.content;
  const inspectorProps = useContentfulInspectorMode();
  return (
    <div
      key={content.sys.id}
      className={cn("relative flex h-full")}
      {...inspectorProps({
        entryId: content.sys.id,
        fieldId: "name",
      })}
    >
      {file?.contentType.includes("image") ? (
        <>
          <ContentfulImage
            key={file?.fileName}
            className={cn(
              " h-full w-full object-cover ",
              mobileFile && "hidden md:flex",
            )}
            alt={file?.fileName}
            imageUrl={file?.url}
            maxWidth="full"
          />
          {mobileFile && (
            <ContentfulImage
              key={mobileFile.fileName}
              className="h-full w-full object-cover md:hidden"
              alt={mobileFile.fileName}
              imageUrl={mobileFile.url}
              maxWidth="full"
            />
          )}
        </>
      ) : (
        <video
          muted
          key={file?.fileName}
          autoPlay
          src={file?.url}
          loop
          playsInline
          className="h-full w-full object-cover"
        />
      )}
      <div
        className={cn(
          "absolute flex w-full flex-col gap-5 self-center px-7",
          positionOn,
        )}
      >
        <div className="flex w-fit flex-col text-4xl font-semibold">
          {title}
        </div>
        {textContent && (
          <div className="w-full max-w-xs flex-wrap gap-5 rounded-md bg-neutralalpha-8 p-5 text-xl font-semibold text-white duration-500">
            <div className={cn("flex w-full flex-col", alignText)}>
              <RichTextRender content={textContent} />
            </div>
          </div>
        )}
        <div className="flex w-fit flex-col gap-5 font-semibold">
          {ctaPrimary && (
            <Button
              as={Link}
              prefetch={"intent"}
              className="w-fit"
              to={url(ctaPrimary.link)}
            >
              {ctaPrimary.title}
            </Button>
          )}
          {ctaSecondary && (
            <Button
              as={Link}
              prefetch={"intent"}
              variant="outline"
              className="w-fit"
              to={url(ctaSecondary.link)}
            >
              {ctaSecondary.title}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
