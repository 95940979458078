import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from "contentful";

import type { TypeLinkSkeleton } from "./TypeLink";

export interface TypeBannerWithLinksFields {
  name?: EntryFieldTypes.Symbol;
  banner: EntryFieldTypes.AssetLink;
  links?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeLinkSkeleton>>;
}

export type TypeBannerWithLinksSkeleton = EntrySkeletonType<
  TypeBannerWithLinksFields,
  "bannerWithLinks"
>;
export type TypeBannerWithLinks<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode,
> = Entry<TypeBannerWithLinksSkeleton, Modifiers, Locales>;

export function isTypeBannerWithLinks<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode,
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>,
): entry is TypeBannerWithLinks<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "bannerWithLinks";
}
